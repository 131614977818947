@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');


/* Base styles */
.navbar {
    display: flex;
    align-items: center;
    justify-content: space-between; /* Adjust to space out elements evenly */
    background-color: rgb(218, 218, 218);
    padding: 10px 20px;
    position: relative;
    flex-wrap: wrap; /* Allow wrapping for smaller screens */
    z-index: +1;
}

.logo-wrapper {
    flex: 1;
    position: relative;
    margin-top: 3px;
    display: flex;
    justify-content: center; /* Center the logo */
}

.logo2 {
    position: relative;
    left: 30px;
    bottom:5px;
    width:25%;
}

.phone-wrapper {
    flex: 1;
    position: relative;
    display: flex;
    justify-content: flex-end;
    order: 2; /* Move to the bottom on smaller screens */
}

.logo-wrapper img {
    max-width: 100%;
    height: auto;
    transition: filter 0.5s; /* Smooth transition for hover effect */
}

.logo-wrapper img:hover {
    filter: brightness(10);
}

.links {
    margin-top: 15px;
    display: flex;
    gap: 20px;
    font-family: "Nunito Sans", sans-serif;
    font-optical-sizing: auto;
    font-weight: 100;
    font-size: small;
    justify-content: center;
    flex: 2;
    order: 1; /* Move to the middle on smaller screens */
}

.links a {
    text-decoration: none;
    color: black;
    font-weight: bold;
    transition: color 0.3s; /* Smooth transition for hover effect */
}

.links a:hover {
    color: red; /* Change link color to red on hover */
}

.phoneNumber {
    font-family: "Nunito Sans";
    font-size: 20px;
    font-weight: bold;
    color: black;
    text-align: left;
    transition: color 0.3s; /* Smooth transition for hover effect */
    font-style: italic;
}

.phoneNumber:hover {
    color: red; /* Change phone number color to red on hover */
    text-decoration: underline;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
    .navbar {
        flex-direction: column;
        align-items: center;
    }

    .links {
        flex-direction: column;
        align-items: center;
        gap: 10px; /* Reduce gap between links */
    }

    .phone-wrapper {
        justify-content: center;
        margin-top: 10px;
    }

    .phoneNumber {
        font-size: 16px; /* Reduce font size for phone numbers */
    }

    .logo2 {
        position: absolute;
        left: 30px;
        bottom:5px;
        width:5%;
    }
}
