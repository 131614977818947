@import url('https://fonts.googleapis.com/css2?family=Italianno&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

.prob{
    background-color: rgb(0, 0, 0);

    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow-x: hidden;
    box-sizing: border-box; /* Ensures padding and border are included in the element's total width and height */
    margin: 0;
    padding: 0;
}

.home{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #000000;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    background-image: url("/public/test.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.mobile {
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    font-family: "Italianno", sans-serif;
    font-size: 70px;
    font-weight: 300;
    position: relative;
    top: 150px;
}

.caption {
    color: rgb(255, 255, 255);
    display: flex;
    justify-content: center;
    font-family: "Italianno", sans-serif;
    font-size: 40px;
    font-weight: 30;
    position: relative;
    top: -100px;
}

.pic-wrapper {
    height: 900px;
    padding-left: 50%;
    padding-right: 50%;
    background-color: rgba(0,0,0,0.5);
}

.galleryWrap {
    margin-top: 100px;
    position: relative;
    width: min-content;
    background-color: rgb(0, 0, 0);
    padding: 10px;
    margin-bottom: 200px;
    justify-content: center;
    left: 50%;
    transform: translate(-50%, 0);
    overflow-x: hidden; /* Prevent horizontal scrolling */
}

.galleryWrap h2 {
    text-align: center;
    color: white;
    font: "Nunito Sans";
    font-weight: 400;
}

.gallery .imgBox {
    width: 300px;
    height: 300px; /* Ensure the height is defined to maintain the aspect ratio */
    overflow: hidden; /* Ensure content overflows are hidden */
}

.gallery img {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the image covers the imgBox */
    transition: opacity 1s ease-in-out;
}

.gallery {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Creates 3 equal columns */
    grid-template-rows: repeat(2, 1fr); /* Creates 2 equal rows */
    gap: 10px;
    padding: 0;
    list-style: none;
}

/* Responsive styles for mobile devices */
@media screen and (max-width: 768px) {
    .home {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-position: top; /* Adjust background position for better fit on smaller screens */
    }

    .mobile {
        font-size: 60px; /* Reduce font size for better fit on smaller screens */
        top: 100px; /* Adjust positioning */
    }

    .caption {
        font-size: 24px; /* Reduce font size for better fit on smaller screens */
        top: -50px; /* Adjust positioning */
    }

    .pic-wrapper {
        height: auto; /* Adjust height for better fit on smaller screens */
    }

    .pic-wrapper img {
        padding-top: 50px;
        width: 400px;
    }

    .gallery {
        display: grid;
        grid-template-columns: repeat(1, 1fr); /* Creates 1 column */
        gap: 10px;
        padding: 0;
        list-style: none;
    }
}

/* Additional styles for very small screens */
@media screen and (max-width: 400px) {
    
}